body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#nav-txt {
  font-weight: bold;
  text-decoration: none;
  font-size: 19px;
  padding: 7px;
  color: #545454;
}

#nav-txt-active {
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  padding: 9px 25px;
  background-color: #E85C0D;
  border-radius: 25px;
}

.innerNavText {
  padding: 5px;
}

/* Flag Logo */
.navbar-custom {
  height: 10vh;
  box-shadow: 0 7px 6px rgba(0, 0, 0, 0.1);
  /* Adds a shadow effect */
  height: 10vh;
  /* Adjust height as needed */
  position: fixed;
  /* Fixes the navbar at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;

}

.flag-logo-container {
  display: flex;
  align-items: center;

}

.flag-logo {
  width: 35px;
  height: 35px;
}

.flag-txt {
  padding: 5% 0 0 5%;
  font-size: 20px;
  font-weight: bolder;
}

/* Home Page */
.home-main-container {
  height: 100vh;
}

.home-page-beneath-nav-container {
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.home-left-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-left: 13%;
  padding-top: 10vh;
}


.contact-now-btn {
  width: 50%;
  background-color: #E85C0D;
  border: 0;
  padding: 2%;
  border-radius: 5px;
  font-weight: bold;
}

.home-image {
  animation: rotate 45s linear infinite;
  display: block;
  margin: 0 auto;
  margin-top: 20vh;
  overflow-y: hidden;
  height: 65%;

}

.txt-head-home {
  font-weight: bold;
  font-size: 2.5vw;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.home-right-container {
  width: 50vw;
  height: 100vh;
  background-color: #E85C0D;
  padding: 0;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-left-container {
    flex: 1 1 100%;
    order: 2;
    padding-left: 25px;
  }

  .txt-head-home {
    font-size: 30px;
    color: #E85C0D;
  }

  .home-right-container {
    width: 100vw;
    height: 60vh;
    background-color: white;
  }

  .home-left-container {
    padding-top: 0;
  }

  .home-image {
    margin-top: 10vh;
    height: 330px;
  }

  .home-page-beneath-nav-container {
    padding-bottom: 10vh;
  }
}

.logo-title {
  color: #E85C0D;
  width: 100px;
  height: 100px;
  font-weight: bolder;
}



/* Key Card */
.key-container-main {
  background-color: #f5f5f5;
  padding: 10vh;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.container-1-key {
  background-color: #fff;
  box-shadow: 0 7px 6px rgba(0, 0, 0, 0.1);
  width: 22%;

  padding: 3%;
  border-radius: 15px;
}

.key-head {
  font-size: 25px;
  font-weight: bolder;
}

.key-para {
  font-size: 13px;
  color: #292929;
}

.key-card-icon {
  color: #E85C0D;
  font-size: 35px;
  margin: 12px 0;
}

@media (max-width: 768px) {
  .key-container-main {
    background-color: #f5f5f5;
    padding: 15vh 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
  }

  .container-1-key {
    background-color: #fff;
    box-shadow: 0 7px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    padding: 5%;
    border-radius: 12px;
    margin: 5vh 0;
  }

  .key-head {
    font-size: 20px;
    font-weight: bolder;
  }

  .key-para {
    font-size: 15px;
    width: 985w;
    color: #292929;
  }

  .key-card-icon {
    color: #E85C0D;
    font-size: 55px;
    margin: 20px 0;
  }

}

.container-1-key:hover {
  background-color: #E85C0D;
  cursor: pointer;
  color: white;

  .key-card-icon {
    color: white;
  }

  .key-para {
    color: white;

  }
}

.orange-heading {
  color: #E85C0D;
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
}

.orange-subheading {
  color: #E85C0D;
  font-family: "Pacifico", cursive;
  font-weight: 300;
  font-size: 23px;
}

.black-heading {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-style: normal;
}

.primary-own {
  color: #0D7C66;
  font-weight: bolder;
  font-size: 18px;
}

.indian-starter-main-container .row .col-lg-6,
.indian-starter-main-container .row .col-md-6,
.indian-starter-main-container .row .col-sm-12 {
  margin-left: -15px;
  /* Adjust this value as needed */
  padding-left: 15px;
  /* Adjust this value as needed */
}

.primary-border {
  border-color: #E85C0D;
}

.primary-text {
  color: #E85C0D;
}

/* Menu */
.menu-main-container {
  margin-top: 5vh;
  text-align: center;
}

.menu-inner-boundary-container {
  border: 2px solid #E85C0D;
  /*  border */
  border-radius: 10px;
  /* Rounded corners */
  background-color: #f8f8f8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
  padding: 1px;
  width: 75vw;
  display: block;
  margin: 0 auto;
}

.menu-category-main-container {
  margin-top: 10vh;
  font-size: 17px;
}

.item-container {
  background-color: rgb(255, 255, 255); 
  cursor: pointer;
  width: 10vw;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #E85C0D;
  font-weight: bold;
  font-size: 15px;

  .text-body-own {
    color: #00000092;
  }
}
#hey{
  padding-top: 15px;
}

.item-container-active {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);  
  font-size: 15px;
  cursor: pointer; 
  width: 10vw;
  height: 8vh;
  border-radius: 5px;
  background-color: #E85C0D;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-pills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu-category-main-container {
    margin-top: 2vh;
    font-size: 17px;
  }

  .nav-item {
    flex: 1 1 auto;
    /* Allows the items to grow and shrink */
    margin: 3px;
    min-width: 30vw;
    /* Set a minimum width for the items */
  }

  .menu-inner-boundary-container {
    width: 87vw;
  }

  .item-container-active {
    background-color: #f8f8f8;
    /* Add borders to the top, bottom, and left sides */
    /* border-top: 3px solid #E85C0D; */
    /* Top border */
    border-bottom: 3px solid #E85C0D;
    /* Bottom border */
    /* border-left: 3px solid #E85C0D; */
    /* Left border */
    padding: 0;
    cursor: pointer;
    width: 35vw;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #E85C0D;
    font-weight: bold;

    .text-body-own {
      color: #00000092;
    }
  }

  .item-container {
    color: #E85C0D;
    padding: 0;
    cursor: pointer;
    width: 32vw;
    border-radius: 5px;
    background-color: #f8f8f8;
    font-weight: bold;
  }

}

.item-container:hover {
  background-color: #e9ecef;
  /* Example hover background color */
}

@media (min-width: 768px) {
  .contact-container {
    width: 90%;
  }
}

.timing-head {
  font-size: 23px;
}

.timing-first-text {
  display: flex;
  justify-content: space-between;
  padding-right: 2vw;
}

.bg-main {
  background-color: #E85C0D;
}

.get-in-touch {
  color: #E85C0D;
  font-size: 40px;
  font-weight: bold;
}

.loti-width {
  height: 40vh;
}

@media (min-width: 768px) {
  .loti-width {
    padding-top: 10vh;
  }

  .loti-container {
    display: flex;
    flex-direction: column;
  }
}


.category-chose-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}


.table td:nth-child(2) {
  max-width: 250px;
  /* Adjust as necessary */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .item-content-padding {
    margin-right: 1vw;
  }
}

.tab-content {
  margin-bottom: 10vh;
}

.indian-flag-text {
  /* background: linear-gradient(to right, #FF671F 16%, #FFB7B7 41%, #046A38 61%); */
  background: #FF671F;
  background-clip: text;
  /* Standard property */
  -webkit-background-clip: text;
  /* Safari and older browsers */
  -webkit-text-fill-color: transparent;
}

.flag-css {
  width: 8%;
  padding-left: 5px;
}

@media (max-width: 768px) {
  .flag-css {
    display: none;
  }



  .indian-flag-text {
    border-bottom: 4px solid #FF671F;
    width: 93%;
    position: relative;
  }

  .indian-flag-text::after {
    content: "";
    position: absolute;
    bottom: -13px;
    left: 0;
    width: 100%;
    border-bottom: 4px solid green;
  }

}


@media (min-width: 767px) {
  .mobile-view {
    display: none;
  }
}

@media (max-width: 766px) {
  .web-view {
    display: none;
  }
}

.input-login {
  border: 2px solid #bbcdff !important;
  /* Use !important if necessary */
}

.input-login:focus {
  border: 2px solid #0044ff !important;
  /* Use !important if necessary */
}

.here {
  padding-left: 10px;
}

.card {
  box-shadow: 0 4px 15px #acc2ff !important;
  /* Add shadow effect */
  background-color: #ffffff;
  /* Optional: set a background color */
  border-radius: 8px;
  /* Optional: round the corners */
  position: relative;
  /* Ensure it's positioned */
  z-index: 10;
  /* Adjust z-index to bring it to the front */
}


.left-sidebar {
  opacity: 1;
  /* Fully visible */
  background-color: white;
  /* Background color of the sidebar */
  box-shadow: 0 4px 8px #acc2ff;
  /* Drop shadow */
  z-index: 1000;
  /* Ensure it appears above other content */
}

.left-sidebar.collapsed {
  width: 0;
  /* Set the collapsed width */
  opacity: 0;
  /* Make it invisible */
  overflow: hidden;
  /* Prevent content overflow */
}

.sidebar-item:nth-child(2) {
  background-color: #bbcdff;
  border-radius: 15px;
}

.navbar-greets {
  text-align: right;
  font-size: 23px;
  padding: 25px 80px;
  background-color: #acc2ff !important;
}


.hamburder-content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .navbar-greets {
    text-align: right;
    font-size: 17px;
    padding: 15px 20px;
  }

  .hamburger-div {
    display: flex;
    justify-content: space-between;
    background-color: #acc2ff !important;
    text-align: right;
  }
}

@media (min-width: 768px) {
  .navbar-greets-clicked {
    padding: 10px 80px;
  }
}

.purple-txt {
  color: #0044ff;
  padding-bottom: 5vh;
}

.main-admin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 80%; */

}

.cat {
  height: 100px;
}

.category-divs-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 buttons per row */
  gap: 10px;
  /* Add some spacing between buttons */
}

@media (max-width: 768px) {
  .category-divs-main {
    grid-template-columns: repeat(2, 1fr);
    /* 2 buttons per row on mobile */
  }

  .dish-main-container {
    padding: 15%;
  }

  .table-bordered {
    margin-top: 10%;
  }
}

.sidebar-item {
  cursor: pointer;
}

.dish-main-container {
  padding: 2%;
}

.table-bordered {
  margin-top: 1%;
}

.delete {
  color: red;
}

.fa-solid {
  cursor: pointer;
}

@media (max-width: 768px) {
  .home-right-container img {
    max-width: 100%;
    height: 70%;
    display: block;
    position: relative;
    padding-top: 5%;
  }
}



#google_translate_element select {
  cursor: pointer;
  background: #F8F8FB;
 color: #2b1a12;
 border: none; 
 font-weight: bold;
 border-radius: 3px;
 padding: 7px 0;  
 border: none;
 font-weight: bold;
 text-decoration: none;
 font-size: 19px;
 text-transform: uppercase; 
 color: #545454;
}

body > .skiptranslate, .goog-logo-link, .goog-te-gadget span, .goog-te-banner-frame, #goog-gt-tt, .goog-te-balloon-frame, div#goog-gt- {
 display: none !important;
}

.goog-te-gadget {
 color: transparent !important;
 font-size: 0px;
 user-select: none;
}

.goog-text-highlight {
 background: none !important;
 box-shadow: none !important;
}




/* Responsive Design */
@media (min-width: 768px) {
  .container{  
    margin-left:40px !important;
  }
  .navbar-brand {
    padding-right: 35vw !important;
  }
 
 } 

 @media (max-width: 768px) {
  
.logo-title { 
  height: 70px;
  width: 70px; 
}
 } 

 .nav-item {
  flex: 1; /* Allows each nav item to take equal space */
  width: 150px; /* Set a specific width for the nav items */
}

/* Alternatively, you can add a custom class for wider boxes */
.nav-item.custom-width {
  width: 200px; /* Adjust this value to your needs */
}

/* Add the existing styles you provided earlier */
.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px; 
  position: relative;
}

@media (min-width: 768px) {
  .hery  {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding-left: 2vw;
    margin-bottom: 10px; 
    position: relative;
  }
  .hery1{
    text-align: center;
  }
}
.footer-logo{
color: #ffb833
}
.footer-widget ul li {
  display: inline-block; 
  width: 50%;
  margin-bottom: 12px;
}
 

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #E85C0D;
  padding: 13px 20px;
  border: 1px solid #E85C0D;
  top: 0;
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
   
}
.copyright-text{
  text-align: center;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #E85C0D;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
 