// ----------------------------------------------
// Variables Style
// ----------------------------------------------


// colors
$white: #fff !default;
$gray-100: #F6F9FC !default;
$gray-200: #EAEFF4 !default;
$gray-300: #DFE5EF !default;
$gray-400: #7C8FAC !default;
$gray-500: #5A6A85 !default;
$gray-600: #2A3547 !default;
$black: #000 !default;

// Color
$blue: #5D87FF !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #FA896B !default;
$orange: #fd7e14 !default;
$yellow: #FFAE1F !default;
$green: #13DEB9 !default;
$teal: #20c997 !default;
$cyan: #539BFF !default;

// Main Colors
$primary: $blue !default;
$secondary: #49BEFF !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$muted: $gray-500 !default;
$dark: $gray-600 !default;
$dark-light: $gray-600 !default;
$light-gray: $gray-100 !default;

// light colors
$light-primary: #ECF2FF;
$light-secondary: #E8F7FF;
$light-info: #EBF3FE;
$light-success: #E6FFFA;
$light-warning: #FEF5E5;
$light-danger: #FBF2EF;
$light-indigo: #EBF3FE;


// Theme Colorss
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "muted": $muted,
  "indigo": $indigo,
  "light-primary": $light-primary,
  "light-secondary": $light-secondary,
  "light-info": $light-info,
  "light-success": $light-success,
  "light-warning": $light-warning,
  "light-danger": $light-danger,
  "light-indigo": $light-indigo,
  "dark-light": $dark-light,
  "light-gray": $light-gray,
  ) !default;

// Spacer
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: 12px,
  7: 30px,
  8: 10px,
  9: 20px,
  ) !default;

// Common
$min-contrast-ratio: 1.7 !default;

// Margins
$enable-negative-margins: true !default;
$enable-shadows: true !default;

// Global
$text-muted: $muted !default;
$grid-gutter-width: 24px !default;

// Font Weight
$font-weight-lighter: 300 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 700 !default;
$font-weight-base: $font-weight-normal !default;

// Buttons
$btn-padding-y: 7px !default;
$btn-padding-x: 16px !default;
$btn-font-weight: 500 !default;
$btn-border-radius: 7px !default;
$btn-box-shadow: unset !default;
$btn-font-size: 14 !default;
$btn-border-radius-lg: 9px !default;

// Font Family
$font-family-sans-serif: "Plus Jakarta Sans", sans-serif !default;

// Font Sizes
$font-sizes: (
  1: 10px,
  2: 12px,
  3: 14px,
  4: 16px,
  5: 18px,
  6: 20px,
  8: 30px,
  ) !default;
$font-size-base: 0.875rem !default;

// Line Heght
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

// Body
$body-bg: $white !default;
$body-color: #5A6A85 !default;

// Heading
$headings-color: $dark !default;
$headings-font-weight: 500 !default;

// Border Color
$border-color: #ebf1f6 !default;

// Card
$card-title-color: $dark !default;
$card-subtitle-color: $dark !default;
$card-spacer-y: 30px !default;
$card-spacer-x: 30px !default;
$card-border-width: 0px !default;
$card-border-color: #ebf1f6 !default;
$card-border-radius: 7px;
$card-box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
  rgb(145 158 171 / 12%) 0px 12px 24px -4px !default;

// Border Radius
$border-radius: 7px !default;
$border-radius-sm: 5px !default;
$border-radius-lg: 15px !default;
$border-radius-xl: 12px !default;

// Badges
$badge-font-size: 14px !default;
$badge-font-weight: $font-weight-normal !default;
$badge-padding-y: 5px !default;
$badge-padding-x: 10px !default;
$badge-border-radius: 4px;


// Table
$table-cell-padding-y: 16px !default;
$table-cell-padding-x: 16px !default;
$table-th-font-weight: 600 !default;
$table-striped-bg: $gray-200 !default;
$table-hover-bg: $gray-100 !default;
$table-border-color: $border-color !default;


// Input
$form-label-color: $gray-600;
$form-label-font-weight: 600;
$form-select-indicator-padding: 38px;
$form-select-border-radius: 7px !default;
$form-select-box-shadow: unset !default;
$input-border-radius: 7px !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $light !default;
$input-border-color: #DFE5EF !default;
$input-bg: transparent !default;
$input-color: $gray-500 !default;
$input-padding-y: 8px;
$input-padding-x: 16px;
$form-check-input-width: 1.188em;
$form-check-min-height: 1.188em;
$form-check-input-border: 1.25px solid #dfe5ef;

// Dropdown
$dropdown-width: 360px !default;
$dropdown-border-color: transparent !default;
$dropdown-item-padding-y: 10px !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-bg: $gray-100;
$dropdown-link-active-color: $dark;
$dropdown-box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
  rgb(145 158 171 / 12%) 0px 12px 24px -4px;

// Modal
$modal-content-border-width: 0 !default;

// navbar
$navbar-light-color: rgba($gray-600, 1) !default;
$navbar-light-hover-color: rgba($primary, 1) !default;
$navbar-dark-color: rgba($gray-300, 1) !default;
$navbar-dark-hover-color: rgba($primary, 1) !default;